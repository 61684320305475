import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import RiseLoader from "react-spinners/RiseLoader";
import Select from "react-select";

import { history } from "@helpers/history";
import { authActions } from "@store";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import styles from "./styles/register.module.css";

const userTypeOptions = [
  {
    value: "individual",
    label: "Физическое лицо",
  },
  {
    value: "legal",
    label: "Юридическое лицо",
  },
];

const companyTypeOptions = [
  {
    value: "company",
    label: "Компания",
  },
  {
    value: "vpo",
    label: "ВПО",
  },
  {
    value: "spo",
    label: "СПО",
  },
];

function Register() {
  const dispatch = useDispatch();
  const authToken = useSelector((x) => x.auth.token);
  const [authError, setAuthError] = useState(null);
  const [userType, setUserType] = useState(userTypeOptions[0]);
  const [companyType, setCompanyType] = useState(null);

  useEffect(() => {
    if (authToken) {
      history.navigate("/personal");
    }
  }, [authToken]);

  const validationSchema = Yup.object().shape({
    lastname: Yup.string().required("Фамилия обязателена"),
    firstname: Yup.string().required("Имя обязателено"),
    middlename: Yup.string(),
    companyType: Yup.object().when("userType", {
      is: (userType) => userType && userType.value === "legal",
      then: (schema) => schema.required("Тип компании обязателен"),
    }),
    inn: Yup.string().when("userType", {
      is: (userType) => userType && userType.value === "legal",
      then: (schema) =>
        schema
          .matches(/^[0-9]+$/, "Допустимы только цифры")
          .min(10, "ИНН должен содержать 10 цифр")
          .required("ИНН обязателен"),
    }),
    kpp: Yup.string().when("userType", {
      is: (userType) => userType && userType.value === "legal",
      then: (schema) =>
        schema
          .matches(/^[0-9]+$/, "Допустимы только цифры")
          .min(9, "КПП должен содержать 9 цифр")
          .required("КПП обязателен"),
    }),
    email: Yup.string()
      .email("Некорректный email")
      .required("Email обязателен"),
    password: Yup.string().required("Пароль обязателен"),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Пароли не совпадают"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({
    lastname,
    firstname,
    middlename,
    userType,
    companyType,
    inn,
    kpp,
    email,
    password,
  }) {
    if (userType && userType.value === "legal") {
      let type = 1;
      switch (companyType.value) {
        case "spo":
          type = 3;
          break;
        case "vpo":
          type = 2;
          break;
        default:
          type = 1;
          break;
      }

      return dispatch(
        authActions.registerV2({
          lastname,
          firstname,
          middlename,
          inn: inn,
          kpp: kpp,
          companyType: type,
          email,
          password,
        })
      )
        .unwrap()
        .then((result) => {
          if (!result.message) {
            const { from } = history.location.state || {
              from: { pathname: "/personal" },
            };
            history.navigate(from.pathname);
          } else {
            setAuthError(result);
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setAuthError(rejectedValueOrSerializedError);
        });
    } else {
      return dispatch(
        authActions.register({
          lastname,
          firstname,
          middlename,
          email,
          password,
        })
      )
        .unwrap()
        .then((result) => {
          if (!result.message) {
            const { from } = history.location.state || {
              from: { pathname: "/personal" },
            };
            history.navigate(from.pathname);
          } else {
            setAuthError(result);
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setAuthError(rejectedValueOrSerializedError);
        });
    }
  }

  return (
    <div className={styles.block}>
      <CardArrow
        customStyles={{
          main: styles.card,
        }}
        sizeArrow={{ width: 20, height: 20 }}
        rotate="rightTop"
      >
        <form className={styles.inner} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.title}>Регистрация</div>
          <div className={styles.formGroup}>
            <input
              type="text"
              {...register("lastname")}
              className={`${
                errors.lastname ? styles.inputError : styles.input
              }`}
              placeholder="Фамилия"
            />
            <div className={styles.errorMessage}>
              {errors.lastname?.message}
            </div>
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              {...register("firstname")}
              className={`${
                errors.firstname ? styles.inputError : styles.input
              }`}
              placeholder="Имя"
            />
            <div className={styles.errorMessage}>
              {errors.firstname?.message}
            </div>
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              {...register("middlename")}
              className={`${
                errors.middlename ? styles.inputError : styles.input
              }`}
              placeholder="Отчество"
            />
            <div className={styles.errorMessage}>
              {errors.middlename?.message}
            </div>
          </div>
          <div className={styles.formGroup}>
            <Select
              options={userTypeOptions}
              placeholder="Тип пользователя"
              value={userType}
              onChange={(e) => {
                setUserType(e);
                setValue("userType", e);
              }}
              className={`${styles.select}`}
              styles={{
                menu: (styles) => ({
                  ...styles,
                  borderRadius: 20,
                }),
                menuList: (styles) => ({
                  ...styles,
                  borderRadius: 20,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
                control: (styles, state) => ({
                  ...styles,
                  borderRadius: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                }),
                option: (styles, state) => ({
                  ...styles,
                  backgroundColor: state.isSelected ? "#014170" : "transparent",
                }),
              }}
            />
          </div>
          {userType.value === "legal" && (
            <>
              <div className={styles.formGroup}>
                <Select
                  options={companyTypeOptions}
                  placeholder="Тип юр. лица"
                  value={companyType}
                  onChange={(e) => {
                    setCompanyType(e);
                    setValue("companyType", e);
                  }}
                  className={`${
                    errors.companyType ? styles.selectError : styles.select
                  }`}
                  styles={{
                    menu: (styles) => ({
                      ...styles,
                      borderRadius: 20,
                    }),
                    menuList: (styles) => ({
                      ...styles,
                      borderRadius: 20,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                    control: (styles, state) => ({
                      ...styles,
                      borderRadius: 20,
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }),
                    option: (styles, state) => ({
                      ...styles,
                      backgroundColor: state.isSelected
                        ? "#014170"
                        : "transparent",
                    }),
                  }}
                />
                <div className={styles.errorMessage}>
                  {errors.companyType?.message}
                </div>
              </div>
              <div className={styles.formGroup}>
                <input
                  type="text"
                  {...register("inn")}
                  className={`${errors.inn ? styles.inputError : styles.input}`}
                  placeholder="ИНН"
                />
                <div className={styles.errorMessage}>{errors.inn?.message}</div>
              </div>
              <div className={styles.formGroup}>
                <input
                  type="text"
                  {...register("kpp")}
                  className={`${errors.kpp ? styles.inputError : styles.input}`}
                  placeholder="КПП"
                />
                <div className={styles.errorMessage}>{errors.kpp?.message}</div>
              </div>
            </>
          )}
          <div className={styles.formGroup}>
            <input
              type="text"
              {...register("email")}
              className={`${errors.email ? styles.inputError : styles.input}`}
              placeholder="Email"
            />
            <div className={styles.errorMessage}>{errors.email?.message}</div>
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              {...register("password")}
              className={`${
                errors.password ? styles.inputError : styles.input
              }`}
              placeholder="Пароль"
            />
            <div className={styles.errorMessage}>
              {errors.password?.message}
            </div>
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              {...register("confirmPassword")}
              className={`${
                errors.confirmPassword ? styles.inputError : styles.input
              }`}
              placeholder="Повторите пароль"
            />
            <div className={styles.errorMessage}>
              {errors.confirmPassword?.message}
            </div>
          </div>
          <div className={styles.submit}>
            {isSubmitting ? (
              <RiseLoader className={styles.bar} color="#014170" />
            ) : (
              <>
                <button className={styles.btn}>Зарегистрироваться</button>
                {authError && (
                  <div className={styles.submitError}>{authError.message}</div>
                )}
              </>
            )}
          </div>
          <div className={styles.addition}>
            <Link to="/personal/login" className={styles.link}>
              Войти
            </Link>
          </div>
        </form>
      </CardArrow>
    </div>
  );
}

export default Register;
