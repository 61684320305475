import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

import { fetchWrapper } from "@helpers/fetchwrapper";
import { store, layoutActions } from "@store";

import portfileIcon from "@content/img/shared/home/png/actions-portfile-icon.png";
import bookIcon from "@content/img/shared/home/png/actions-book-icon.png";
import documentIcon from "@content/img/shared/home/png/actions-document-icon.png";
import backgroundIcon from "@content/img/shared/home/png/actions-background-icon.png";

import styles from "./styles/actions.module.css";

function Actions() {
  const [data, setData] = useState(null);
  const [firstShow, setFirstShow] = useState(false);
  const [secondShow, setSecondShow] = useState(false);
  const [thirdShow, setThirdShow] = useState(false);

  const setLayout = (value) => {
    store.dispatch(layoutActions.setMount(false));
    store.dispatch(layoutActions.setWho(value));
  };

  const getElems = (data, type) => {
    if (data) {
      let item = data.filter((p) => p.type === type);
      if (item.length > 0) {
        return item[0].elems;
      }
    }
    return [];
  };

  const loadData = useCallback(() => {
    fetchWrapper.get("/data/actions.json").then((res) => {
      setData(res);
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className={styles.block}>
      <div className={`container ${styles.cards}`}>
        <div className={styles.info}>
          <div className={styles.header} onClick={() => setLayout("employer")}>
            <div className={styles.picture}>
              <img
                src={portfileIcon}
                className={styles.icon}
                alt="Работодателям"
              />
              <img
                src={backgroundIcon}
                className={styles.background}
                alt="Фон"
              />
            </div>
            <div className={styles.title}>Работодателям</div>
          </div>
          <div className={`${styles.card} ${firstShow ? styles.show : null}`}>
            <div className={styles.actions}>
              {getElems(data, 1).map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className={styles.action}
                  target="_blank"
                >
                  {item.title}
                </Link>
              ))}
              <div className={styles.hide}>
                <div
                  className={styles.button}
                  onClick={(e) => {
                    e.preventDefault();
                    setFirstShow(false);
                  }}
                >
                  Скрыть
                </div>
              </div>
            </div>
            <div className={styles["actions-hidden"]}></div>
            <div className={styles.more}>
              <div
                className={styles.button}
                onClick={(e) => {
                  e.preventDefault();
                  setFirstShow(true);
                }}
              >
                Посмотреть все
              </div>
            </div>
          </div>
        </div>
        <div className={styles.lineContainer}>
          <div className={styles.line}></div>
        </div>
        <div className={styles.info}>
          <div className={styles.header} onClick={() => setLayout("college")}>
            <div className={styles.picture}>
              <img src={bookIcon} className={styles.icon} alt="СПО/ВПО" />
              <img
                src={backgroundIcon}
                className={styles.background}
                alt="Фон"
              />
            </div>
            <div className={styles.title}>СПО/ВПО</div>
          </div>
          <div className={`${styles.card} ${secondShow ? styles.show : null}`}>
            <div className={styles.actions}>
              {getElems(data, 2).map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className={styles.action}
                  target="_blank"
                >
                  {item.title}
                </Link>
              ))}
              <div className={styles.hide}>
                <div
                  className={styles.button}
                  onClick={(e) => {
                    e.preventDefault();
                    setSecondShow(false);
                  }}
                >
                  Скрыть
                </div>
              </div>
            </div>
            <div className={styles["actions-hidden"]}></div>
            <div className={styles.more}>
              <div
                className={styles.button}
                onClick={(e) => {
                  e.preventDefault();
                  setSecondShow(true);
                }}
              >
                Посмотреть все
              </div>
            </div>
          </div>
        </div>
        <div className={styles.lineContainer}>
          <div className={styles.line}></div>
        </div>
        <div className={styles.info}>
          <div className={styles.header} onClick={() => setLayout("schoolboy")}>
            <div className={styles.picture}>
              <img
                src={documentIcon}
                className={styles.icon}
                alt="Абитуриентам"
              />
              <img
                src={backgroundIcon}
                className={styles.background}
                alt="Фон"
              />
            </div>
            <div className={styles.title}>Абитуриентам</div>
          </div>
          <div className={`${styles.card} ${thirdShow ? styles.show : null}`}>
            <div className={styles.actions}>
              {getElems(data, 3).map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className={styles.action}
                  target="_blank"
                >
                  {item.title}
                </Link>
              ))}
              <div className={styles.hide}>
                <div
                  className={styles.button}
                  onClick={(e) => {
                    e.preventDefault();
                    setThirdShow(false);
                  }}
                >
                  Скрыть
                </div>
              </div>
            </div>
            <div className={styles["actions-hidden"]}></div>
            <div className={styles.more}>
              <div
                className={styles.button}
                onClick={(e) => {
                  e.preventDefault();
                  setThirdShow(true);
                }}
              >
                Посмотреть все
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Actions;
