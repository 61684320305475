import React from "react";
import { TbUserCircle } from "react-icons/tb";

import styles from "./styles/header.module.css";

function Header({ user }) {
  return (
    <div className={styles.block}>
      <div className={styles.foreground}>
        <div className={styles.background}></div>
      </div>
      <div className={styles.info}>
        <div className={styles.icon}>
          <TbUserCircle size={40} />
        </div>
        <div className={styles.description}>
          <h3 className={styles.fio}>{`${user.Lastname} ${user.Firstname}`}</h3>
          <div className={styles.profession}>Разработчик</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
